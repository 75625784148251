import React from 'react'

const Tos = () => {
  return (
    <div className="spacing">
      <h1>Terms and Conditions of Use of Questionable.io</h1>

      <h5>1 Acceptance The Use Of Questionable.io Terms and Conditions</h5>
      <p>
        Your access to and use of Questionable.io is subject exclusively to these Terms and Conditions. You will not use
        the Website for any purpose that is unlawful or prohibited by these Terms and Conditions. By using the Website
        you are fully accepting the terms, conditions and disclaimers contained in this notice. If you do not accept
        these Terms and Conditions you must immediately stop using the Website.
      </p>

      <h5>2 Advice</h5>
      <p>
        The contents and test results of Questionable.io website do not constitute advice and should not be relied upon
        in making or refraining from making, any decision.
      </p>

      <h5>3 Change of Use</h5>
      <p>
        Questionable.io reserves the right to:
        <br />
        3.1 change or remove (temporarily or permanently) the Website or any part of it without notice and you confirm
        that Questionable.io shall not be liable to you for any such change or removal and.
        <br />
        3.2 change these Terms and Conditions at any time, and your continued use of the Website following any changes
        shall be deemed to be your acceptance of such change.
        <br />
      </p>

      <h5>4 Links to Third Party Websites</h5>
      <p>
        Questionable.io Website may include links to third party websites that are controlled and maintained by others.
        Any link to other websites is not an endorsement of such websites and you acknowledge and agree that we are not
        responsible for the content or availability of any such sites.
      </p>

      <h5>5 Copyright</h5>
      <p>
        5.1 All copyright, trade marks and all other intellectual property rights in the Website and its content
        (including without limitation the Website design, text, graphics and all software and source codes connected
        with the Website) are owned by or licensed to Questionable.io or otherwise used by Questionable.io as permitted
        by law.
        <br />
        5.2 In accessing the Website you agree that you will access the content solely for your internal use. None of
        the content, that is not created by you, may be downloaded, copied, reproduced, transmitted, stored, sold or
        distributed without the prior written consent of the copyright holder.
      </p>

      <h5>6 Use of Trademarks</h5>
      <p>
        Each party shall strictly comply with all standards with respect to the other party's marks which may be
        furnished by such party from time to time. Further, neither party shall create a combination mark consisting of
        one or more marks of the other party. All uses of the other party's marks shall inure to the benefit of the
        party owning such mark. Notwithstanding the foregoing, Questionable.io may identify Customer by name, with or
        without use of the Customer's trademark, in general promotional lists of Questionable.io's customers without
        Customer's prior consent. Questionable.io may deduce your company name from the domain extension of the email
        address you use to sign up to any of the Questionable.io Services with. Nothing in this Agreement transfers to
        us any rights or ownership in or to your Marks.
      </p>

      <h5>7 Disclaimers and Limitation of Liability</h5>
      <p>
        7.1 The Website is provided on an AS IS and AS AVAILABLE basis without any representation or endorsement made
        and without warranty of any kind whether express or implied, including but not limited to the implied warranties
        of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and
        accuracy.
        <br />
        7.2 To the extent permitted by law, Questionable.io will not be liable for any indirect or consequential loss or
        damage whatever (including without limitation loss of business, opportunity, data, profits) arising out of or in
        connection with the use of the Website.
        <br />
        7.3 Questionable.io makes no warranty that the functionality of the Website will be uninterrupted or error free,
        that defects will be corrected or that the Website or the server that makes it available are free of viruses or
        anything else which may be harmful or destructive.
        <br />
        7.4 Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability of
        Questionable.io for death or personal injury as a result of the negligence of Questionable.io or that of its
        employees or agents.
      </p>

      <h5>8 Indemnity</h5>
      <p>
        You agree to indemnify and hold Questionable.io and its employees and agents harmless from and against all
        liabilities, legal fees, damages, losses, costs and other expenses in relation to any claims or actions brought
        against Questionable.io arising out of any breach by you of these Terms and Conditions or other liabilities
        arising out of your use of this Website.
      </p>

      <h5>9 Severance</h5>
      <p>
        If any of these Terms and Conditions should be determined to be invalid, illegal or unenforceable for any reason
        by any court of competent jurisdiction then such Term or Condition shall be severed and the remaining Terms and
        Conditions shall survive and remain in full force and effect and continue to be binding and enforceable.
      </p>

      <h5>10 Governing Law</h5>
      <p>
        These Terms and Conditions shall be governed by and construed in accordance with the law of USA and you hereby
        submit to the exclusive jurisdiction of the USA courts.
      </p>

      <p>For any further information please email us at hello@questionable.io</p>
    </div>
  )
}

export default Tos
