import React from 'react'
import DocumentLayout from '../../DocumentLayout'
import Centered from '../../Centered'
import Tos from '../../shared/ui/Tos'

const TermsOfService = () => (
  <DocumentLayout className="subpage-white">
    <Centered>
      <br />
      <Tos />
      <br />
    </Centered>
  </DocumentLayout>
)

export default TermsOfService
